import React, { useState, useRef, useEffect} from "react"
import { Drawer, Form, Row, Col ,Button, Space} from "antd"
import { StoreInput } from "../../input";
import {StoreSingleUpload} from "../../index";
import { apiCalls } from "../../../shared"

const AddUpdateCategory= ({visible, catData, getCategories, onClose})=>{

    const [form] = Form.useForm()
    const btnRef = useRef() 
    const selectButton = () => btnRef.current.click()
    const [loading, setLoading]= useState(false)
    useEffect(()=>{
        if(visible && catData)
        {
            form.setFieldsValue({
                title: catData?.title,
                logo: catData?.logo
            })
        }
        else 
            form.resetFields()
    }, [catData])
    const addUpdateCategory= async ()=>{
        setLoading(true)
        let data=form.getFieldsValue()
        let formData = new FormData()
        if(catData?.id)
            formData.append(`id`, catData?.id)
        formData.append(`title`, data?.title)
        formData.append(`logo`, data?.logo)
        const result= await apiCalls?.categories?.addUpdateCategory(formData)
        setLoading(false)
        onClose()
        if(result)
            getCategories()
    }
    return (
        <Drawer
            title='Add new category'
            onClose={onClose}
            open={visible}
            width={500}
            footer={
                <div>
                    <Space>
                        <Button
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <Button  
                            type="primary"
                            loading={loading}
                            onClick={selectButton}
                        >
                            Save
                        </Button>
                    </Space>
                </div>
            }
        >
            <div className="flex-col-Space-between height-100">
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={true}
                    onFinish={()=> addUpdateCategory()}
                >
                    <Row gutter={16}>
                        <Col span={24}>
                            <StoreSingleUpload
                                name='logo'
                                label='Category Logo'
                                required
                                message='please upload category logo'
                                form={form}
                                logo={catData?.logo}
                            />
                        </Col>
                        <Col span={24}>
                            <StoreInput
                                name='title'
                                label='Category name / title'
                                required
                                message='Please enter category title'
                                placeholder='e.g Entertainment'
                                value={form.getFieldValue('title')}
                            />
                        </Col>
                    </Row>
                    <Button 
                        htmlType="submit" 
                        ref={btnRef}
                        className="display-none"
                    >
                        save
                    </Button>
                </Form>
            </div>
        </Drawer>
    )
}
export {AddUpdateCategory}