import React from 'react'
import { AppStatistics, ApplicationsStatistics } from '../../components'
import { Space } from 'antd'

const Dashboard = () => {
  return (
    <Space direction='vertical' size={20} className='w-100'>
        <AppStatistics />
        <ApplicationsStatistics/>
    </Space>
  )
}

export {Dashboard}