import React, { useState } from "react"
import { Drawer, Form, Row, Col ,Button, Space, theme, message, Upload, Image, Select, DatePicker, Modal} from "antd"
import { StoreInput } from "../input"
import {
    PlusOutlined
} from '@ant-design/icons'
import { AlertModal } from "../AlertModal";
const { Option } = Select;
const { Dragger } = Upload;
const { useToken } = theme;

const props = {
    name: 'file',
    action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const EditAppDrawer = ({visible, onClose}) => {
    const [form] = Form.useForm()
    const { token } = useToken();
    const [ visiblemodal, setVisibleModal ] = useState(false)
    const [hidedraw, setHideDraw ] = useState()

    const handleChange = (value) => {
        console.log(`selected ${value}`);
      };


      const [previewOpen, setPreviewOpen] = useState(false);
      const [previewImage, setPreviewImage] = useState('');
      const [previewTitle, setPreviewTitle] = useState('');
      const [fileList, setFileList] = useState([]);
      const handleCancel = () => setPreviewOpen(false);
      const handlePreview = async (file) => {
          if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj);
          }
          setPreviewImage(file.url || file.preview);
          setPreviewOpen(true);
          setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
      };
      const handleChangeone = ({ fileList: newFileList }) => setFileList(newFileList);
      const uploadButton = (
          <div>
          <PlusOutlined />
          <div
              style={{
              marginTop: 8,
              }}
          >
              Upload
          </div>
          </div>
      );
    

  return (
    <div>
        <Drawer
            title='Netflix'
            onClose={onClose}
            open={visible}
            width={500}
            extra={[
                <Button className="border0" onClick={()=>{setVisibleModal(true);setHideDraw(onClose)}}>
                    <Image src="/assets/icons/delete.png" width={'32px'} preview={false} />
                </Button>
            ]}
        >
            <div className="flex-col-Space-between height-100">
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={true}
                    onFinish={()=>{}}
                    style={{height:'100%',overflowY:"auto"}}
                >
                    <div className="py-3">
                        <Dragger {...props}>
                            <p className="ant-upload-drag-icon">
                                <Image src='/assets/icons/upload.png' width={'41.6px'} preview={false} />
                            </p>
                            <p className="ant-upload-text">Upload profile picture</p>
                        </Dragger>
                    </div>
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={12}>
                            <StoreInput
                                name='catname'
                                label='Category name  /  title'
                                required
                                message='Please enter category name  /  title'
                                placeholder='Add application name'
                                value={form.getFieldValue('catname')}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                            <Form.Item
                                name="selectcate"
                                label="Application category"
                                rules={[
                                {
                                    required: true,
                                },
                                ]}
                            >
                                <Select
                                placeholder="Music and Series"
                                onChange={handleChange}
                                allowClear
                                >
                                    <Option value="1">Category 1</Option>
                                    <Option value="2">Category 2</Option>
                                    <Option value="3">Category 3</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                            <StoreInput
                                name='appver'
                                label='Application version'
                                required
                                message='Please enter application version'
                                placeholder='Add application name'
                                value={form.getFieldValue('appver')}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                            <Form.Item
                                name="applang"
                                label="Application Language"
                                rules={[
                                {
                                    required: true,
                                },
                                ]}
                            >
                                <Select
                                placeholder="Add application language"
                                onChange={handleChange}
                                allowClear
                                >
                                    <Option value="1">English</Option>
                                    <Option value="2">French</Option>
                                    <Option value="3">Arabic</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                            <Form.Item
                                name="uploaddate"
                                label="Upload date"
                                rules={[
                                {
                                    required: true,
                                },
                                ]}
                            >
                                <DatePicker className="w-100" value={form.getFieldValue('uploaddate')} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                            <StoreInput
                                name='appsz'
                                label='Application Size'
                                required
                                message='Please enter application size'
                                placeholder='Add application size'
                                value={form.getFieldValue('appsz')}
                            />
                        </Col>
                        <Col span={24}>
                            <StoreInput
                                name='developer'
                                label='Developer'
                                required
                                message='Please enter application developer name'
                                placeholder='Add application developer'
                                value={form.getFieldValue('developer')}
                            />
                        </Col>
                        <Col span={24}>
                            <StoreInput
                                textArea
                                name='appdec'
                                label='Application description'
                                placeholder='Add application description'
                                value={form.getFieldValue('appdec')}
                            />
                        </Col>
                        <Col span={24}>
                            <Space direction='vertical' className='w-100' align='start'>
                                <label>Receipt Copy</label>
                                <Upload
                                    action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                                    listType="picture-card"
                                    fileList={fileList}
                                    onPreview={handlePreview}
                                    onChange={handleChangeone}
                                >
                                    {fileList.length >= 8 ? null : uploadButton}
                                </Upload>
                                <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                                    <img
                                    alt="example"
                                    style={{
                                        width: '100%',
                                    }}
                                    src={previewImage}
                                    />
                                </Modal>
                            </Space>
                        </Col>
                    </Row>
                </Form>
                <div className="w-100">
                    <Space style={{justifyContent:"end"}} className="w-100">
                        <Button 
                            block
                            type=""
                            size="large"
                            onClick={onClose}
                            style={{borderColor: token.border2,width: '120px',borderRadius: token.borderrad3}}
                        >
                            Cancel
                        </Button>
                        <Button  
                            block
                            type="primary"
                            size="large"
                            style={{borderColor: token.border2,width: '120px',borderRadius: token.borderrad3}}
                        >
                            Save
                        </Button>
                    </Space>
                </div>
            </div>
        </Drawer>
        <AlertModal 
            visiblemodal={visiblemodal}
            onclose={()=>setVisibleModal(false)}
        />
    </div>
  )
}

export {EditAppDrawer}