import { configureStore } from "@reduxjs/toolkit";
import {
	login,
	dashboard,
	categories,
	applications
} from "./action"
const store = configureStore({
	reducer: {
		login,
		dashboard,
		categories,
		applications
	},
})
export default store