import React from 'react'
import { Image, Modal, Space, Typography, theme, Button } from 'antd'

const { Title, Text } = Typography
const { useToken } = theme;
const AlertModal = ({visiblemodal,onclose}) => {
    const { token } = useToken();
  return (
    <div>
        <Modal width={450}  open={visiblemodal} onOk={onclose} onCancel={onclose} centered footer={null}>
            <Space className='w-100 py-2' align='center' size={10} direction='vertical'>
                <Image src='/assets/icons/alert-ic.png' width={'50px'} preview={false} />
                <Title level={4} className='my-0'>Alert</Title>
                <Text className='label'>Are you sure you want to permanently delete the application</Text>
                <Space style={{justifyContent:"end",marginTop:'15px'}} className="w-100">
                    <Button 
                        block
                        type=""
                        size="large"
                        onClick={onclose}
                        style={{borderColor: token.border2,width: '120px',borderRadius: token.borderrad3}}
                    >
                        Cancel
                    </Button>
                    <Button  
                        block
                        type="primary"
                        size="large"
                        style={{borderColor: token.border2,width: '120px',borderRadius: token.borderrad3}}
                    >
                        Confirm
                    </Button>
                </Space>
            </Space>
        </Modal>
    </div>
  )
}

export {AlertModal}