import {Row, Col, Space, Button, Typography, Image} from "antd"
export const ModuleTopHeading=({name, onClick})=>{
    return (
        <Row>
            <Col span={24}>
                <Space className="align-center">
                    <Typography.Title level={3} className="my-0">
                        {
                            name
                        }
                    </Typography.Title>
                    <Button 
                        type="primary" 
                        shape="circle" 
                        size='small' 
                        className="center"
                        icon={
                            <Image 
                                src='./assets/icons/add-icon.png' 
                                width={'12px'} 
                                preview={false} 
                            />
                        }
                        onClick={onClick}
                    />
                </Space>
            </Col>
        </Row>
    )
}