import { domainUrl } from "../../"
import { checkAuthorization } from "../../helpers"
import { message } from "antd"
const addUpdateCategory = (formData) => {
    const {userToken}= checkAuthorization()
    var myHeaders = new Headers()
    myHeaders.append("Authorization", `${userToken}`)
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: formData
    }
    let url = formData?.get('id') ? '/updateCategory' : '/createCategory'
    return (
        fetch(domainUrl + url, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success(result?.message)
                return 1
            }
            else 
                throw result?.message
        })
        .catch(error => {
            message.error(error)
            return 0
        })
    )
}
const removeCategory = (itemID) => {
    const {userToken}= checkAuthorization()
    var myHeaders = new Headers()
    myHeaders.append("Authorization", `${userToken}`)
    var requestOptions = {
      method: 'Delete',
      headers: myHeaders,
      redirect: 'follow'
    }
    return (
        fetch(domainUrl + '/deleteCategory/'+ itemID, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success(result?.message)
                return 1
            }
            else 
                throw result?.message
        })
        .catch(error => {
            message.error(error)
            return 0
        })
    )
}
const categories={
    addUpdateCategory,
    removeCategory
}
export {categories}