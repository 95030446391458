import { useSelector, useDispatch } from 'react-redux';
import { Card, Col, Row, Typography,Space, Image, theme, Skeleton } from 'antd'
import { useEffect, useState } from 'react';
import { actionsApi } from '../../shared';

const { useToken } = theme;
const { Title, Text } = Typography
const AppStatistics = () => {
    const dispatch= useDispatch()
    const { token } = useToken()
    const {data, loading}= useSelector(state => state?.dashboard)
    const [state, setState]= useState(null)

    useEffect(()=>{
        dispatch(actionsApi?.getStatistics())
    }, [])
    useEffect(()=>{
        if(data)
        {
            setState([
                {title:'Total applications', count: data?.applications, icon: "android-tv.png"},
                {title:'Total downloads', count: data?.totalDownload, icon: "downloads.png"},
                // {title:'Total customers', count: data?.totalCustomer, icon: "customers.png"},
                // {title:'New customers', count: data?.newCustomer, icon: "new-customers.png"},
            ])
        }
        else 
        setState(null)
    }, [data])

  return (
    <div>
        <Row gutter={[24,24]}>
            {
                loading ?
                new Array(4)?.fill(null)?.map((_, index)=>
                    <Col 
                        xs={24} sm={24} md={12} lg={12} xl={6} 
                        key={'skelton-card-' + index}
                    >
                        <Skeleton.Button 
                            active 
                            size='large' 
                            shape='square'
                            block
                            style={{width:'100%', height:'114px'}}
                            className='radius-20'
                        />
                    </Col>
                )
                :
                state?.map((stat, index)=>
                    <Col 
                        xs={24} sm={24} md={12} lg={12} xl={6}
                        key={'stat-card-' + index}
                    >
                        <Card 
                            style={{
                                borderRadius: token?.cardRadius,
                                boxShadow: token.cardshadow,
                                width:"100%"
                            }}
                            className='border-grey'
                        >
                            <Space className='w-100 space-between'>
                                <Space 
                                    direction='vertical'
                                    size={6}
                                >
                                    <Text className='my-0 label-color'>{stat?.title}</Text>
                                    <Title level={3} className='text-blue my-0'>{stat?.count}</Title>
                                </Space>
                                <Image 
                                    src={'/assets/icons/'+stat?.icon} 
                                    alt='store app'
                                    preview={false}
                                />
                            </Space>
                        </Card>
                    </Col>
                )
            }
        </Row>
    </div>
  )
}

export {AppStatistics}