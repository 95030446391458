import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../';
import { checkAuthorization } from '../../../helpers';
import { message } from 'antd'


export const getCategories = createAsyncThunk('getCategories',
    async (object, { dispatch, getState }) => {

        const {userToken}= checkAuthorization()
        dispatch(gettingcategories())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", userToken)
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append("pageNO", object?.pageNo)
        myHeaders.append("recordsPerPage", object?.pageSize)
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({title: object?.title})
        };
        fetch(domainUrl + `/showCategory`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    dispatch(categoriesResponse(result?.categories))
                    dispatch(setPages({pageNo: object?.pageNo, pageSize: object?.pageSize, totalRecords: result?.totalRecords}))
                }
                else 
                    throw result?.message
            })
            .catch(error => { 
                dispatch(categoriesClear())
                message.error(error) 
            })
    }
)

const CategoriesSlice = createSlice({
    name: 'categories',
    initialState: {
        data: null,
        loading: false,
        pagination:{
            pageNo:1,
            pageSize: 20,
            totalRecords:0,
        },
        filters: {
            title: '',
        }
    },
    reducers: {
        gettingcategories: (state) => {
            state.loading = true
        },
        categoriesResponse: (state, action) => {
            state.data = action.payload;
            state.loading = false
        },
        categoriesClear: (state) => {
            state.data = null;
            state.loading = false
        },
        changeCategoriesFilters: (state, action) => {
            state.filters = action.payload
        },
        setPages:(state, action)=>{
            state.pagination= action.payload
        },
    },
})
export const { 
    gettingcategories, categoriesResponse, categoriesClear, changeCategoriesFilters, setPages
} = CategoriesSlice.actions;
export default CategoriesSlice.reducer;