import React,{ useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {Row, Col, Form} from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { AddUpdateCategory, ModuleTopHeading, StoreInput, CategoryCard, CardSkeleton } from '../../components';
import { actionsApi } from '../../shared'

const Categories = () => {
  const [form] = Form.useForm()
  const dispatch= useDispatch()
  const {data, loading, filters, pagination}= useSelector(state => state?.categories)
  const [visible, setVisible]= useState(false)
  const [catData, setCatData]= useState(null)
  useEffect(()=>{
    dispatch(actionsApi?.getCategories({...filters, ...pagination, pageNo: 1}))
    form.setFieldsValue({
        ...filters,
    })
    }, [])
    const filter=()=>{
        let data= form.getFieldsValue()
        dispatch(actionsApi?.getCategories({...data, ...pagination, pageNo:1}))
        dispatch(actionsApi?.changeCategoriesFilters({...data}))
    }
    const call=(pageNo=1, pageSize=8)=>{
        dispatch(actionsApi?.getCategories({...filters, pageNo, pageSize}))
    }

  return (
    <>
        <Row 
            gutter={[12,12]} 
            align={'middle'}
        >
            <Col md={12}>
                <ModuleTopHeading
                    name='Categories'
                    onClick={()=>setVisible(true)}
                />
            </Col>
            <Col md={12} className='justify-end'>
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={true}
                    onFinish={filter}
                    className='search-bar-cover'
                >
                    <StoreInput
                        name="title"
                        size='large'
                        label={null}
                        value={form.getFieldValue('title') || ''}
                        suffix={<SearchOutlined />}
                        placeholder='search category'
                        className='radius-30 page-top-search-bar'
                    />
                </Form>
            </Col>
            <Col md={24}>
                {
                    loading && !data ?
                    <Row gutter={[24, 24]}>
                        {
                            new Array(8)?.fill(null)?.map(_=> 
                                <Col span={6}>
                                    <CardSkeleton/>
                                </Col>
                            )
                        }
                    </Row>
                    :
                    <Row gutter={[24, 24]}>
                        {
                            data?.map((category, index)=> {
                                return (
                                    <Col span={6} key={index}>
                                        <CategoryCard 
                                            data={category}
                                            getCategories={call}
                                            setCatData={setCatData}
                                            setVisibleDrawer={setVisible}
                                        />
                                    </Col>
                                )
                            })
                        }
                    </Row>
                }  
            </Col>
        </Row>
        <AddUpdateCategory
            visible={visible}
            getCategories={call}
            catData={catData}
            onClose={()=> {setVisible(false); setCatData(null)}}
        />
    </>
  )
}

export {Categories}