import React from 'react'
import { Image, Modal, Space, Typography, Button } from 'antd'

const { Title, Text } = Typography

const DeleteModal = ({visible, title, loading, onClose, removeItem}) => {
  return (
    <div>
        <Modal 
            width={450}  
            open={visible} 
            onOk={onClose} 
            onCancel={onClose} 
            centered 
            footer={
                <div className='flex-end'>
                    <Space>
                        <Button 
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <Button  
                            type="primary"
                            loading={loading}
                            onClick={removeItem}
                        >
                            Confirm
                        </Button>
                    </Space>
                </div>
            }
        >
            <Space 
                className='w-100 py-2'
                align='center' 
                size={10} 
                direction='vertical'
            >
                <Image 
                    src='/assets/icons/alert-ic.png' 
                    width={'50px'} 
                    preview={false} 
                />
                <Title level={4} className='my-0'>Remove {title}</Title>
                <Text className='label'>
                    Are you sure you want to permanently delete this {title}?
                </Text>
            </Space>
        </Modal>
    </div>
  )
}

export {DeleteModal}