
//login
import { login, loginResponse, loginClear, AuthCheck } from "./action/Login"
//dashboard
import { getStatistics, getApplicationsDetail, changeApplicationsDetailFilters } from "./action/dashboard"
//categories
import { getCategories, changeCategoriesFilters } from "./action/Categories"
//Applications
import { getAllApplications, changeApplicationsFilters, getCategoriesLookup } from "./action/Applications"

export const actionsApi = {
    login, 
    loginResponse, 
    loginClear, 
    AuthCheck, 
    //dashobard 
    getStatistics,
    getApplicationsDetail, 
    changeApplicationsDetailFilters,
    //categories
    getCategories,
    changeCategoriesFilters,
    //Applications
    getAllApplications,
    changeApplicationsFilters,
    getCategoriesLookup
}
