import { domainUrl } from "../../"
import { checkAuthorization } from "../../helpers"
import { message } from "antd"
const addUpdateApplication = (formData) => {
    const {userToken}= checkAuthorization()
    var myHeaders = new Headers()
    myHeaders.append("Authorization", `${userToken}`)
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: formData
    }
    let url = formData?.get('id') ? '/updateApplication' : '/storeApplicaiton'
    return (
        fetch(domainUrl + url, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success(result?.message)
                return 1
            }
            else 
                throw result?.message
        })
        .catch(error => {
            message.success(error)
            return 0
        })
    )
}
const removeApplication = (itemID) => {
    const {userToken}= checkAuthorization()
    var myHeaders = new Headers()
    myHeaders.append("Authorization", `${userToken}`)
    var requestOptions = {
      method: 'Delete',
      headers: myHeaders,
      redirect: 'follow'
    }
    return (
        fetch(domainUrl + '/deleteApplication/'+ itemID, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success(result?.message)
                return 1
            }
            else 
                throw result?.message
        })
        .catch(error => {
            message.error(error)
            return 0
        })
    )
}
const applications={
    addUpdateApplication,
    removeApplication
}
export {applications}