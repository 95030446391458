import React, { useState } from "react"
import { Drawer, Form, Row, Col ,Button, Space, theme, message, Upload, Image, Select, DatePicker, Modal} from "antd"
import { StoreInput } from "../input"
import {
    PlusOutlined
} from '@ant-design/icons'
import { AlertModal } from "../AlertModal";
const { Option } = Select;
const { Dragger } = Upload;
const { useToken } = theme;

const props = {
    name: 'file',
    action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

const EditCategoryDrawer = ({visible, onClose}) => {
    const [form] = Form.useForm()
    const { token } = useToken();
    const [ visiblemodal, setVisibleModal ] = useState(false)
    const [hidedraw, setHideDraw ] = useState()

    const handleChange = (value) => {
        console.log(`selected ${value}`);
      };


  return (
    <div>
        <Drawer
            title='Movies and Series'
            onClose={onClose}
            open={visible}
            width={500}
            extra={[
                <Button className="border0" onClick={()=>{setVisibleModal(true);setHideDraw(onClose)}}>
                    <Image src="/assets/icons/delete.png" width={'32px'} preview={false} />
                </Button>
            ]}
        >
            <div className="flex-col-Space-between height-100">
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={true}
                    onFinish={()=>{}}
                >
                    <div className="py-3">
                        <Dragger {...props}>
                            <p className="ant-upload-drag-icon">
                                <Image src='/assets/icons/upload.png' width={'41.6px'} preview={false} />
                            </p>
                            <p className="ant-upload-text">Upload profile picture</p>
                        </Dragger>
                    </div>
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={12}>
                            <StoreInput
                                name='catname'
                                label='Category name  /  title'
                                required
                                message='Please enter category name  /  title'
                                placeholder='Add application name'
                                value={form.getFieldValue('catname')}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                            <Form.Item
                                name="uploadapp"
                                label="Uploaded application"
                                rules={[
                                {
                                    required: true,
                                },
                                ]}
                            >
                                <Select
                                placeholder="Cartoon network"
                                onChange={handleChange}
                                allowClear
                                >
                                    <Option value="1">Category 1</Option>
                                    <Option value="2">Category 2</Option>
                                    <Option value="3">Category 3</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <div className="w-100">
                    <Space style={{justifyContent:"end"}} className="w-100">
                        <Button 
                            block
                            type=""
                            size="large"
                            onClick={onClose}
                            style={{borderColor: token.border2,width: '120px',borderRadius: token.borderrad3}}
                        >
                            Cancel
                        </Button>
                        <Button  
                            block
                            type="primary"
                            size="large"
                            style={{borderColor: token.border2,width: '120px',borderRadius: token.borderrad3}}
                        >
                            Save
                        </Button>
                    </Space>
                </div>
            </div>
        </Drawer>
        <AlertModal 
            visiblemodal={visiblemodal}
            onclose={()=>setVisibleModal(false)}
        />
    </div>
  )
}

export {EditCategoryDrawer}