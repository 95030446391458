import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Card, Col, Row, Typography,Space, Image, theme, Form, Dropdown, Table, Avatar, Button} from 'antd'
import { StoreInput } from '../../components'
import { TableLoader, actionsApi } from '../../shared';
import { FilterOutlined, SearchOutlined } from '@ant-design/icons';
const { useToken } = theme;
const { Title, Text } = Typography

const ApplicationsStatistics = () => {
    const dispatch= useDispatch()
    const [form] = Form.useForm()
    const { token } = useToken()
    const {applicationsDetail, loadingApplicationsDetail, filters, pagination}= useSelector(state => state?.dashboard)
    const [order, setOrder]= useState(1)
    const items = [
        {
            label: <Text>A-Z</Text>,
            key: '0',
            disabled: order ===1,
        },
        {
            label: <Text>Z-A</Text>,
            key: '1',
            disabled: order === 2,
        },
        {
            label: <Text>Most downloads</Text>,
            key: '2',
            disabled: order === 3,
        },
        {
            label: <Text>Less downloads</Text>,
            key: '3',
            disabled: order === 4,
        },
    ];


    const columns = [
    {
        title: <Text style={{color: token.colorgray}}>Application name</Text>,
        dataIndex: 'title',
        key: 'title',
        render: (column, row)=> (
            <Space size={20}>
                <Avatar 
                    // style={{background:"transparent"}} 
                    size={52} 
                    icon={<Image src={row?.logo} alt='store app application icon' preview={false}/>} 
                    alt='store app admin panel'
                />
                <Text strong>
                    {
                        column
                    }
                </Text>
            </Space>
        )
    },
    {
        title: <Text style={{color: token.colorgray}}>Category</Text>,
        dataIndex: 'categoryTitle',
        key: 'categoryTitle',
        render: column => <Text strong>{column}</Text>
    },
    {
        title: <Text style={{color: token.colorgray}}>Version</Text>,
        dataIndex: 'version',
        key: 'version',
        render: column => <Text strong>{column || "1.0.0"}</Text>
    },
    {
        title: <Text style={{color: token.colorgray}}>Downloads</Text>,
        dataIndex: 'download',
        key: 'download',
        render: column => <Text strong>{column}</Text>
    },
    ]

    useEffect(()=>{
        dispatch(actionsApi?.getApplicationsDetail({...filters, ...pagination, pageNo: 1}))
        form.setFieldsValue({
            ...filters,
        })
        setOrder(filters?.order)
    }, [])
    const filter=(order)=>{
        let data= form.getFieldsValue()
        dispatch(actionsApi?.getApplicationsDetail({...data, order, ...pagination, pageNo:1}))
        dispatch(actionsApi?.changeApplicationsDetailFilters({...data, order}))
    }
    const call=(pageNo=1, pageSize=10)=>{
        dispatch(actionsApi?.getApplicationsDetail({...filters, pageNo, pageSize}))
    }
    const onClick = ({ key }) => {
        key = parseInt(key) + 1
        setOrder(key)
        filter(key)
      };


  return (
    <div>
        <Card 
            style={{borderRadius: token?.cardRadius}}
            className='border-grey'
            >
            <Row gutter={[24,24]}>
                <Col md={12}>
                    <div>
                        <Title level={3} className='my-0'>Applications</Title>
                    </div>
                </Col>
                <Col md={12}>
                    <Space 
                        align='baseline' 
                        className='w-100 search-bar-cover' 
                        size={10} 
                        style={{justifyContent:"end"}}
                        >
                        <Form
                            form={form}
                            layout="vertical"
                            initialValues={true}
                            onFinish={filter}
                        >
                            <StoreInput
                                name="title"
                                size='large'
                                label={null}
                                value={form.getFieldValue('title') || ''}
                                suffix={<SearchOutlined/>}
                                placeholder='search application'
                                className='radius-30 page-top-search-bar'
                            />
                        </Form>
                        <Dropdown
                            menu={{
                            items,
                            onClick
                            }}
                            trigger={['click']}
                            arrow
                            icon={<FilterOutlined />}
                        >
                            <Button
                                type='primary'
                                icon={<FilterOutlined/>}
                                size='large'
                            >
                                Filter
                            </Button>
                        </Dropdown>
                    </Space>
                </Col>
                <Col span={24}>
                    <Table 
                        size='large'
                        columns={columns} 
                        dataSource={applicationsDetail} 
                        pagination={{ 
                            hideOnSinglePage: true, 
                            total: pagination?.totalRecords,
                            pageSize: pagination?.pageSize,
                            defaultPageSize: pagination?.pageSize,
                            current: pagination?.pageNo,
                            size: "default",
                            pageSizeOptions:['20', '50', '100'],
                            onChange: (pageNo, pageSize)=> call(pageNo, pageSize),
                            showTotal: total => <Button>Total Apps: {total}</Button>
                        }}
                        loading={
                            {
                              ...TableLoader,
                              spinning: loadingApplicationsDetail
                            }
                        }
                    />
                </Col>
            </Row>
        </Card>
    </div>
  )
}

export {ApplicationsStatistics}