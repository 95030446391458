import React, { useState, useRef, useEffect} from "react"
import { useSelector, useDispatch } from "react-redux";
import { Drawer, Form, Row, Col ,Button, Space} from "antd"
import { StoreInput } from "../../input";
import {StoreSelect, StoreTimePicker, StoreSingleUpload, StoreMultipleUploads, StoreSingleFileUpload} from "../../index";
import { actionsApi, apiCalls } from "../../../shared"
import dayjs from "dayjs";

const AddUpdateApplication= ({visible, appData, getAllApplications, onClose})=>{

    const dispatch= useDispatch()
    const {categoriesLookup}= useSelector(state => state?.applications)
    const [form] = Form.useForm()
    const btnRef = useRef() 
    const selectButton = () => btnRef.current.click()
    const [loading, setLoading]= useState(false)

    useEffect(()=>{
        dispatch(actionsApi?.getCategoriesLookup())
    }, [dispatch])
    useEffect(()=>{
        if(visible && appData)
        {
            form.setFieldsValue({
                logo: appData?.logo,
                application: appData?.application,
                images: appData?.image,
                applicationSize: appData?.applicationSize,
                title: appData?.title,
                categoryID: appData?.categoryID,
                version: appData?.version,
                language: appData?.language,
                updateDate: dayjs(appData?.updateDate),
                developer: appData?.developer,
                description: appData?.description
            })
        }
        else 
        form.resetFields()
    }, [visible, appData, form])
    const addUpdateApplication= async ()=>{
        setLoading(true)
        let data=form.getFieldsValue()
        console.log(data)
       
        let formData = new FormData()
        if(appData?.id)
            formData.append(`id`, appData?.id)
        formData.append(`logo`, data?.logo)
        formData.append(`application`, data?.application)
        formData.append(`applicationSize`, data?.application?.size ? ((data?.application?.size /1000000).toFixed(2)+ ' Mb') : appData?.applicationSize)
        formData.append(`title`, data?.title)
        formData.append(`categoryID`, data?.categoryID)
        formData.append(`version`, data?.version)
        formData.append(`language`, data?.language)
        formData.append(`updateDate`, data?.updateDate)
        formData.append(`developer`, data?.developer)
        formData.append(`description`, data?.description)

        for(let i=0;i<data?.images?.length;i++)
            {
                if(typeof data?.images[i]==='string')
                    formData.append(`oldUrls[]`, data?.images[i])
                else
                    formData.append(`images[]`, data?.images[i])
            }
        const result= await apiCalls?.applications?.addUpdateApplication(formData)
        setLoading(false)
        onClose()
        if(result)
            getAllApplications()
    }
    return (
        <Drawer
            title='Add new Application'
            onClose={onClose}
            open={visible}
            width={600}
            footer={
                <div className="flex-end">
                    <Space>
                        <Button
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <Button  
                            type="primary"
                            loading={loading}
                            onClick={selectButton}
                        >
                            Save
                        </Button>
                    </Space>
                </div>
            }
        >
            <div className="flex-col-Space-between height-100">
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={true}
                    onFinish={()=> addUpdateApplication()}
                >
                    <Row gutter={16}>
                        <Col span={24}>
                            <StoreSingleUpload
                                name='logo'
                                label='Application Logo'
                                required
                                message='please upload application logo'
                                form={form}
                                logo={appData?.logo || null}
                            />
                        </Col>
                        <Col span={24}>
                            <StoreSingleFileUpload
                                name='application'
                                label='Upload application'
                                required
                                message='please upload application logo'
                                form={form}
                                application={appData?.application}
                            />
                        </Col>
                        <Col span={12}>
                            <StoreInput
                                name='title'
                                label='Application name / title'
                                required
                                message='Please enter application title'
                                placeholder='e.g IP TV'
                                value={form.getFieldValue('title')}
                            />
                        </Col>
                        <Col span={12}>
                            <StoreSelect
                                name='categoryID'
                                label='Application category'
                                required
                                message='please select category'
                                placeholder='e.g Music'
                                value={form.getFieldValue('categoryID')}
                                options={categoriesLookup}
                            />
                        </Col>
                        <Col span={12}>
                            <StoreInput
                                name='version'
                                label='Application version'
                                required
                                message='Please enter application version'
                                placeholder='e.g 3.2.41'
                                value={form.getFieldValue('version') || ''}
                            />
                        </Col>
                        <Col span={12}>
                            <StoreSelect
                                name='language'
                                label='Application language'
                                required
                                message='Please select application language'
                                placeholder='e.g German'
                                value={form.getFieldValue('language')}
                                options={[
                                    {id:"English", name: "English"},
                                    {id:"Arabic", name: "Arabic"},
                                    {id:"German", name: "German"},
                                ]}
                            />
                        </Col>
                        <Col span={12}>
                            <StoreTimePicker
                                datePicker
                                name='updateDate'
                                label='Update date'
                                required
                                message='Please select update date'
                                placeholder='e.g 12/15/2023'
                            />
                        </Col>
                        <Col span={12}>
                            <StoreInput
                                name='developer'
                                label='Application developer'
                                required
                                message='Please enter developer name'
                                placeholder='e.g Repla Technologies'
                                value={form.getFieldValue('developer')}
                            />
                        </Col>
                        <Col span={24}>
                            <StoreInput
                                textArea
                                rows={4}
                                name='description'
                                label='Application description'
                                required
                                message='Please enter description'
                                placeholder='e.g Use IP Tv app for......'
                                value={form.getFieldValue('description')}
                            />
                        </Col>
                        <Col span={24}>
                            <StoreMultipleUploads
                                name='images'
                                label='Add application screenshots'
                                required
                                message='please upload application screenshots'
                                form={form}
                                appData={appData}
                            />
                        </Col>
                    </Row>
                    <Button 
                        htmlType="submit" 
                        ref={btnRef}
                        className="display-none"
                    >
                        save
                    </Button>
                </Form>
            </div>
        </Drawer>
    )
}
export {AddUpdateApplication}