import React,{ useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {Image, Row, Col, Form, Pagination, Button} from 'antd'
import { ModuleTopHeading, StoreInput, CardSkeleton, ApplicationCard, AddUpdateApplication } from '../../components';
import { actionsApi } from '../../shared'

const suffix = (
  <Image 
    src='/assets/icons/search-ic.png' 
    width={'14.86px'}
    />
)

const Applications = () => {
  const [form] = Form.useForm()
  const dispatch= useDispatch()
  const {data, loading, filters, pagination}= useSelector(state => state?.applications)
  const [visible, setVisible]= useState(false)
  const [appData, setAppData]= useState(null)

    useEffect(()=>{
        dispatch(actionsApi?.getAllApplications({...filters, ...pagination, pageNo: 1}))
        form.setFieldsValue({
            ...filters,
        })
    }, [])
    const filter=()=>{
        let data= form.getFieldsValue()
        dispatch(actionsApi?.getAllApplications({...data, ...pagination, pageNo:1}))
        dispatch(actionsApi?.changeApplicationsFilters({...data}))
    }
    const call=(pageNo=1, pageSize=8)=>{
        dispatch(actionsApi?.getAllApplications({...filters, pageNo, pageSize}))
    }
    

  return (
    <>
        <Row 
            gutter={[12, 12]} 
            align={'middle'}
        >
            <Col md={12}>
                <ModuleTopHeading
                    name='Applications'
                    onClick={()=>setVisible(true)}
                />
            </Col>
            <Col md={12} className='justify-end search-bar-cover'>
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={true}
                    onFinish={filter}
                >
                    <StoreInput
                        name="title"
                        size='large'
                        label={null}
                        value={form.getFieldValue('title') || ''}
                        suffix={suffix}
                        placeholder='search application'
                        className='radius-30 page-top-search-bar'
                    />
                </Form>
            </Col>
            <Col md={24}>
                {
                    loading && !data ?
                    <Row gutter={[24, 24]}>
                        {
                            new Array(8)?.fill(null)?.map(_=> 
                                <Col span={6}>
                                    <CardSkeleton/>
                                </Col>
                            )
                        }
                    </Row>
                    :
                    <Row gutter={[24, 24]}>
                        {
                            data?.map((application, index)=> {
                                return (
                                    <Col span={6} key={index}>
                                        <ApplicationCard 
                                            data={application}
                                            getAllApplications={call}
                                            setAppData={setAppData}
                                            setVisibleDrawer={setVisible}
                                        />
                                    </Col>
                                )
                            })
                        }
                        {
                            data?.length ?
                            <Col span={24} className="justify-center">
                                <Pagination
                                    hideOnSinglePage= {true}
                                    total= {pagination?.totalRecords}
                                    pageSize= {pagination?.pageSize}
                                    defaultPageSize= {pagination?.pageSize}
                                    current= {pagination?.pageNo}
                                    size= {"default"}
                                    pageSizeOptions= {['20', '50', '100']}
                                    onChange= {(pageNo, pageSize)=> call(pageNo, pageSize)}
                                    showTotal= {total => <Button>Total Apps: {total}</Button>}
                                />
                            </Col>
                            :<></>
                        }
                    </Row>
                }  
            </Col>
        </Row>
        <AddUpdateApplication
            visible={visible}
            getAllApplications={call}
            appData={appData}
            onClose={()=> {setVisible(false); setAppData(null)}}
        />
    </>
  )
}

export {Applications}