import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants'
import { checkAuthorization } from '../../../helpers';
import { message } from 'antd'

export const getStatistics = createAsyncThunk('getStatistics',
    async (_, { dispatch, getState }) => {

        const {userToken}= checkAuthorization()
        dispatch(gettingStatistics())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", userToken)
        var requestOptions = {
            method: 'get',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(domainUrl + `/statistics`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    dispatch(statisticsResponse(result))
                }
                else 
                    throw result?.message
            })
            .catch(error => { 
                dispatch(statisticsClear())
                message.error(error) 
            })
    }
)
export const getApplicationsDetail = createAsyncThunk('getStatistics',
    async (object, { dispatch, getState }) => {

        const {userToken}= checkAuthorization()
        dispatch(gettingApplicationsDetail())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", userToken)
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append("pageNO", object?.pageNo)
        myHeaders.append("recordsPerPage", object?.pageSize)
        var requestOptions = {
            method: 'Post',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({title: object?.title, order: object?.order})
        };
        fetch(domainUrl + `/applicationFilter`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    dispatch(applicationsDetailResponse(result?.applications))
                    dispatch(setPages({pageNo: object?.pageNo, pageSize: object?.pageSize, totalRecords: result?.totalRecords}))
                }
                else 
                    throw result?.message
            })
            .catch(error => { 
                dispatch(applicationsDetailClear())
                message.error(error) 
            })
    }
)

const DashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        data: null,
        loading: false,
        applicationsDetail: null,
        loadingApplicationsDetail: false,
        pagination:{
            pageNo:1,
            pageSize: 10,
            totalRecords:0,
        },
        filters: {
            title: '',
            order: 1,
        },
    },
    reducers: {
        gettingStatistics: state => {
            state.loading=true
            state.data=null
        },
        statisticsResponse: (state, action)=>{
            state.data= action.payload
            state.loading= false
        },
        statisticsClear: (state)=>{
            state.data= null
            state.loading= false
        },
        gettingApplicationsDetail: state => {
            state.loadingApplicationsDetail=true
            state.applicationsDetail=null
        },
        applicationsDetailResponse: (state, action)=>{
            state.applicationsDetail= action.payload
            state.loadingApplicationsDetail= false
        },
        applicationsDetailClear: (state)=>{
            state.applicationsDetail= null
            state.loadingApplicationsDetail= false
        },
        changeApplicationsDetailFilters: (state, action) => {
            state.filters = action.payload
        },
        setPages:(state, action)=>{
            state.pagination= action.payload
        },
    },
})
export const { 
    gettingStatistics, statisticsResponse, statisticsClear,
    gettingApplicationsDetail, applicationsDetailResponse, applicationsDetailClear,
    changeApplicationsDetailFilters, setPages
} = DashboardSlice.actions;
export default DashboardSlice.reducer;