import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../';
import { checkAuthorization } from '../../../helpers';
import { message } from 'antd'


export const getAllApplications = createAsyncThunk('getApplications',
    async (object, { dispatch, getState }) => {

        const {userToken}= checkAuthorization()
        dispatch(gettingApplications())
        var myHeaders = new Headers()
        myHeaders.append("Authorization", userToken)
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append("pageNO", object?.pageNo)
        myHeaders.append("recordsPerPage", object?.pageSize)
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({title: object?.title})
        };
        fetch(domainUrl + `/showApplication`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    dispatch(applicationsResponse(result?.applications))
                    dispatch(setPages({pageNo: object?.pageNo, pageSize: object?.pageSize, totalRecords: result?.totalRecords}))
                }
                else 
                    throw result?.message
            })
            .catch(error => { 
                dispatch(applicationsClear())
                message.error(error) 
            })
    }
)
export const getCategoriesLookup = createAsyncThunk('getCategoriesLookup',
    async (_, { dispatch, getState }) => {

        const {userToken}= checkAuthorization()
        dispatch(gettingCategoriesLookup())
        var myHeaders = new Headers()
        myHeaders.append("Authorization", userToken)
        var requestOptions = {
            method: 'get',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(domainUrl + `/categoryDropDown`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    dispatch(categoriesLookupResponse(result?.categories?.map(category => ({id: category?.id, name: category?.title}))))
                }
                else 
                    throw result?.message
            })
            .catch(error => { 
                dispatch(categoriesLookupClear())
                message.error(error) 
            })
    }
)

const ApplicationsSlice = createSlice({
    name: 'applications',
    initialState: {
        data: null,
        loading: false,
        pagination:{
            pageNo:1,
            pageSize: 20,
            totalRecords:0,
        },
        filters: {
            title: '',
        },
        categoriesLookup: null,
        categoriesLookupLoading: false
    },
    reducers: {
        gettingApplications: (state) => {
            state.loading = true
        },
        applicationsResponse: (state, action) => {
            state.data = action.payload;
            state.loading = false
        },
        applicationsClear: (state) => {
            state.data = null;
            state.loading = false
        },
        changeApplicationsFilters: (state, action) => {
            state.filters = action.payload
        },
        setPages:(state, action)=>{
            state.pagination= action.payload
        },
        gettingCategoriesLookup: (state) => {
            state.categoriesLookupLoading = true
        },
        categoriesLookupResponse: (state, action) => {
            state.categoriesLookup = action.payload;
            state.categoriesLookupLoading = false
        },
        categoriesLookupClear: (state) => {
            state.categoriesLookup = null;
            state.categoriesLookupLoading = false
        },
    },
})
export const { 
    gettingApplications, applicationsResponse, applicationsClear, changeApplicationsFilters, setPages,
    gettingCategoriesLookup, categoriesLookupResponse, categoriesLookupClear,
} = ApplicationsSlice.actions;
export default ApplicationsSlice.reducer;