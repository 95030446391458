import React, { useState } from 'react'
import { Card, Typography, Space, theme } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { ActionButton } from '../../';
import { DeleteModal } from '../../'
import "./index.css"
import { apiCalls } from '../../../shared'

const { useToken } = theme
const { Title } = Typography

const ApplicationCard = ({data, getAllApplications, setVisibleDrawer, setAppData}) => {
    const { token } = useToken();
    const [visible, setVisible]= useState(false)
    const [itemID, setItemID]= useState(null)
    const [loading, setLoading]= useState(false)
    const removeItem= async ()=>{
        setLoading(true)
        const result= await apiCalls?.applications?.removeApplication(itemID)
        setLoading(false)
        setVisible(false)
        if(result)
            getAllApplications()
    }
  return (
    <>
        <Card 
            style={{
                background: token.bgtransparent,
                borderColor: token.border2,
                borderRadius: token.borderrad2, 
                height:"100%"
            }}
            className='category-card'
        >
            <Space 
                direction='vertical' 
                size={10} 
                className='w-100'
            >
                <div 
                    className='center radius-6 item-image-cover'
                    >
                    <div style={{backgroundImage: `url(${data?.logo})`}}>
                    </div>
                </div>
                <div className='space-between'>
                    <Title level={5} className='label my-0'>{data?.title}</Title>
                    <Space>
                        <ActionButton
                            title={'Edit'}
                            icon={<EditOutlined/>}
                            onClick={()=>{setAppData(data); setVisibleDrawer(true)}}
                        />
                        <ActionButton
                            title={'Delete'}
                            icon={<DeleteOutlined/>}
                            onClick={()=>{setVisible(true); setItemID(data?.id)}}
                            danger
                        />
                    </Space>
                </div>
            </Space>
        </Card>
        <DeleteModal
            visible={visible}
            title='application'
            loading={loading}
            removeItem= {removeItem}
            onClose={()=> setVisible(false)}
        />
    </>
  )
}

export {ApplicationCard}